import packageJson from '../../package.json'

export function getVersion() {
  if (process.env.REACT_APP_COMMIT_DATE) {
    // Running in GitLab CI/CD pipeline
    return process.env.REACT_APP_API_URL !== "https://api.charterzentrum.de/v1"
      ? process.env.REACT_APP_COMMIT_DATE + " - API: staging-api"
      : process.env.REACT_APP_COMMIT_DATE;
  } else {
    // Running locally
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    return formattedDate + " - DEV";
  }
}

  
  export function getAppInfo() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const versionDate = getVersion();
    const version = packageJson.version + " " + "beta";
    const companyName = 'Yacht- und Charterzentrum GmbH';
  
    return {
      version,
      versionDate,
      companyName,
      year,
    };
  }
  