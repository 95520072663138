import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useTranslation } from "react-i18next";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { AppContext } from "../contexts/AppContext";

import "../App.css";
import { registerLocale } from  "react-datepicker";

import { enUS, de, es, ru, hr, pl} from 'date-fns/locale/';
registerLocale('en-US', enUS)
registerLocale('de', de)
registerLocale('es', es)
registerLocale('ru', ru)
registerLocale('hr', hr)
registerLocale('pl', pl)

/*
Task block:
Every task is rendered and managed by this component.
*/

// To change dynamically later
let isAnotherTaskOpen = false;

// forwardRef is used to allow parendts (yachts) to call tasks function (childs)
const Task = React.forwardRef((props, ref) => {
  // Access global states and variables from context
  const {
    yachaUrl,
    isATaskActive,
    isLiveEnv,
    updateIsTaskActive,
    updateYachtInProgress,
    updateLastEventTime,
  } = useContext(AppContext);

  const { t, i18n } = useTranslation();

  let [isCardOpen, setIsCardOpen] = useState(props.isCardOpen);
  let [isTaskDone, setIsTaskDone] = useState(
    props.task.status === "completed" ? true : false
  );
  let [isTaskActive, setIsTaskActive] = useState(
    props.task.status === "inprogress" ? true : false
  );
  let [isTaskInProgress, setIsTaskInProgress] = useState(false);
  let [isTaskOpen, setIsTaskOpen] = useState(
    props.task.status === "open" ? true : false
  );
  let [hasMultipleTasks, setHasMultipleTasks] = useState(
    props.hasMultipleTasks
  );
  let [isTaskForOtherWorker, setIsTaskForOtherWorker] = useState(false);

  const nowTime = Math.floor(Date.now() / 1000);
  const elapsedSeconds = nowTime - props.task.started;
  // setElapsedMinutes(Math.floor(elapsedSeconds / 60));
  let [elapsedMinutes, setElapsedMinutes] = useState(Math.floor(elapsedSeconds / 60));

  const current = new Date();
  const date = `${current.getDate()}.${current.getMonth() +
    1}.${current.getFullYear()}`;

  /* states to control in which states components/tasks are in. Used to render components dynamically*/
  let [user_id, setUser_id] = useState(props.user_id);
  let [yacht_id, setYacht_id] = useState(props.yacht_id);

  let [isTaskTodo, setIsTaskTodo] = useState(props.isTaskTodo);

  let [task_id, setTask_id] = useState(props.task.task_item_id);
  let [isTaskInProgressByOther, setIsTaskInProgressByOther] = useState(
    props.isTaskInProgressByOther
  );

  let [statusAction, setStatusAction] = useState(
    props.task.status === "inprogress"
      ? "task_action_finish"
      : "task_action_start"
  );

  let [clientEarlyDeparture, setClientEarlyDeparture] = useState(false);
  let [clientEarlyDepartureTime, setClientEarlyDepartureTime] = useState(new Date().getTime())
  let [clientEarlyDepartureDate, setClientEarlyDepartureDate] = useState(new Date().getTime())
  let [isTaskCheckoutInprogress, setIsTaskCheckoutInprogress] = useState(false);

  let [showDateTimePicker, setShowDateTimePicker] = useState(false);
  let [selectedDate, setSelectedDate] = useState(null);
  let [selectedTime, setSelectedTime] = useState(null);

  // New state for yacht berths and pontoons
  const [yachtBerth_real, setYachtBerth_real] = useState(props.yacht_berth_real);
  const [yachtPontoon_real, setYachtPontoon_real] = useState(props.yacht_pontoon_real);
  const [fremdsteg, setFremdsteg] = useState('');
  const [fremdstegChecked, setFremdstegChecked] = useState(false);
  const [showCheckoutWarning, setShowCheckoutWarning] = useState(false);



  let token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : sessionStorage.getItem("token");

  let selectedBase = localStorage.getItem("selectedBase") || localStorage.getItem("defaultBase") || "[]";

  // State to store the time since the task started or ended
  
  function calculateTimeSinceStart() {
    if (props.task.status === "completed" && props.task.ended && props.task.ended > 0) {
      return new Date(props.task.ended * 1000).toLocaleString([], {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
    } else if (props.task.started && props.task.started > 0) {
      const nowTime = Math.floor(Date.now() / 1000);
      const elapsedSeconds = nowTime - props.task.started;
  
      if (elapsedSeconds < 60) {
        return t("task_inProgress_just_started");
      } else {
        const elapsedMinutes = Math.floor(elapsedSeconds / 60);
        if (elapsedMinutes < 60) {
          return `${t("task_inProgress_since")} ${elapsedMinutes} ${elapsedMinutes > 1 ? t("minutes") : t("minute")}`;
        } else {
          const elapsedHours = Math.floor(elapsedMinutes / 60);
          const remainingMinutes = elapsedMinutes % 60;
          return `${t("task_inProgress_since")} ${elapsedHours} ${elapsedHours > 1 ? t("hours") : t("hour")} ${remainingMinutes} ${remainingMinutes > 1 ? t("minutes") : t("minute")}`;
        }
      }
    }
    return null;
  }
  
  

  const [timeSinceStart, setTimeSinceStart] = useState(() => calculateTimeSinceStart());
  
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeSinceStart(calculateTimeSinceStart());
    }, 60000); // Update every minute
  
    return () => {
      clearInterval(interval);
    };
  }, []);
  

  let [iconState, setIconState] = useState(
    props.task.status === "open" ? "bi-exclamation-triangle" : ""
  );
  let [iconColor, setIconColor] = useState(
    props.task.status === "open" ? "orange" : ""
  );
  let [buttonType, setButtonType] = useState(props.buttonType);
  let [showWarning, setShowWarning] = useState(false);
  let [isStopTask, setIsStopTask] = useState(false);
  let [isFinishTask, setIsFinishTask] = useState(false);

  const [showResetConfirmation, setShowResetConfirmation] = useState(false);
  
  function toggleResetConfirmation() {
    setShowResetConfirmation(!showResetConfirmation);
  } 

   /* Get time whenever a task starts or ends */
  let currentTime = new Date().toLocaleString([], {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  let currentTimeHours = new Date().toLocaleString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  /* Functions to handel events and states changes. Later to be moved in /helper */
  // Used to hide/show dialogue askting for confirmation to finish task
  function toggleStopTask(e) {
    if (localStorage.getItem("AppOffline") === "true") {
      return;
    }
    setIsFinishTask(false);
    if (isTaskActive) {
      setIsStopTask(!isStopTask);
    }
  }

  // Stop task after confirmation
  function StopTask(e) {
    if (localStorage.getItem("AppOffline") === "true") {
      return;
    }
    if (isTaskActive) {
      setIsTaskActive(false);
      setIsStopTask(!isStopTask);
      setIsTaskTodo(true);
      setStatusAction("task_action_start");
      setTimeSinceStart(null);
      setIconState("bi-exclamation-triangle");
      setIconColor("orange");
      setIsFinishTask(false);
      setIsTaskOpen(true);
      setShowWarning(false);
      updateIsTaskActive(false);
      updateYachtInProgress(false);
      setTimeSinceStart(null)
      isAnotherTaskOpen = false;
      updateTaskStatus(
        user_id,
        yacht_id,
        task_id,
        Math.floor(Date.now() / 1000),
        "aborted"
      );
      if (props.task.name === "checkout") {
        // Anzeige der Vorzeitigen Abreise nur bei "checkout"
        setIsTaskCheckoutInprogress(false);
      }
      props.getTasks();
      updateLastEventTime(new Date())
    }
  }

  // What should happen if an action (start, finish..) button is clicked
  function confirmFinishTask(e) {
    if (localStorage.getItem("AppOffline") === "true") {
      return;
    }
    if (!hasMultipleTasks) {
      // Dont trigger parents to handel event
      // e.stopPropagation();
    }

    
    if (isTaskActive) {
      if (props.task.name === "checkout" && (!yachtPontoon_real || !yachtBerth_real) && (props.base === "heiligenhafen")) {
        setShowCheckoutWarning(true); // Show warning for checkout task
        return;
      }
      // finish task
      setIsFinishTask(true);
    } else if (isTaskOpen && (!isATaskActive || props.isAllowedMultipleTasks)) {
        // if (
        //   props.task.fixed_worker === "1" &&
        //   props.user_id != props.task.worker && !props.isBaseManager
        //   ) {
        //     setIsTaskForOtherWorker(true);
        //     return;
        // }
        // start task
        if (props.task.name === "checkout") {
          // Anzeige der Vorzeitigen Abreise nur bei "checkout"
          setIsTaskCheckoutInprogress(true);
        }

        setIsTaskActive(true);
        setStatusAction("task_action_finish");
        setTimeSinceStart(t("task_inProgress_just_started"));
        setIconState("");
        setIconColor("");
        setButtonType("btn-success");
        setShowWarning(false);
        setIsTaskOpen(false);
        isAnotherTaskOpen = true;
        // static for tests purpos only
        updateIsTaskActive(true);
        updateYachtInProgress(props.yacht_id);
        updateTaskStatus(
          user_id,
          yacht_id,
          task_id,
          Math.floor(Date.now() / 1000),
          "inprogress"
          );
      props.getTasks();
      updateLastEventTime(new Date())
    } else if (!isTaskDone) {
      if (!hasMultipleTasks || (hasMultipleTasks && isCardOpen)) {
        setShowWarning(true);
      }
    }
  }

  // Used to control confirm dialogue to finish a task
  function toggleConfirmFinishTask(e) {
    if (localStorage.getItem("AppOffline" === "true")) return;

    if (isTaskActive) {
      setIsFinishTask(!isFinishTask);
    }
  }

  // Finish a task
  function finishTask(e) {
    if (localStorage.getItem("AppOffline") === "true") {
      return;
    }

    if (isTaskActive) {
      setIsFinishTask(!isFinishTask);
      setIsTaskActive(false);
      setIsTaskDone(true);
      setIsCardOpen(false);
      setStatusAction("task_status_completed");
      setTimeSinceStart(currentTime);
      setIconState("bi-check-square");
      setIconColor("green");
      setButtonType("btn-success");
      setIsStopTask(false);
      updateIsTaskActive(false);
      updateYachtInProgress(false);
      isAnotherTaskOpen = false;
      updateTaskStatus(
        user_id,
        yacht_id,
        task_id,
        Math.floor(Date.now() / 1000),
        "completed"
      );
      props.getTasks();
      updateLastEventTime(new Date())
      if (!hasMultipleTasks) {
        props.setYachtStatus(t("yacht_status_completed"));
        props.setYachtButtonType("btn-success");
      }
    }
  }

  // Reset a task
  function resetTask() {
    setShowResetConfirmation(false);
    setIsFinishTask(false);
    setIsTaskDone(false);
    setIsCardOpen(true);
    setStatusAction("task_action_start");
    setTimeSinceStart(null);
    setIconState("bi-exclamation-triangle");
    setIconColor("orange");
    setButtonType("btn-success");
    setShowWarning(false);
    setIsTaskOpen(true);
    isAnotherTaskOpen = false;
    updateTaskStatus(user_id, yacht_id, task_id, 0, "reset");
    props.getTasks();
    updateLastEventTime(new Date())
  }
  

  // Used to control if a warning message can be showed or not
  function hideWarning(e) {
    setShowWarning(false);
    setIsTaskForOtherWorker(false);
  }

  function updateTaskStatus(user_id, yacht_id, task_id, date, status) {
    let url = `${yachaUrl}/yacha/checklist/tasks/update/?user_id=${user_id}&yacht_id=${yacht_id}&task_id=${task_id}&timestamp=${date}&status=${status}&base=${selectedBase}`;
    if (clientEarlyDeparture) {
      url = url + `&client_early_departure=1&client_departure_date=${clientEarlyDepartureDate}&client_departure_time=${clientEarlyDepartureTime}`
      console.log("clientEarlyDeparture URL: " + url);
      setIsTaskCheckoutInprogress(false)
    }
    if (props.base === "heiligenhafen") {
      if (props.task.name === "checkout") {
          url = url + `&pontoon_real=${yachtPontoon_real}&berth_real=${yachtBerth_real}`;
      } else if (props.task.name === "office" || props.task.name === "customer_on_site") {
          if (yachtPontoon_real !== "") {
              url = url + `&pontoon_real=${yachtPontoon_real}`;
          }
          if (yachtBerth_real !== "") {
              url = url + `&berth_real=${yachtBerth_real}`;
          }
      }
      console.log("############ Heiligenhafen ############## ")
      console.log("Heiligenhafen Liegeplatz request URL: " + url);

  }
    console.log("Update request URL: " + url);
    // Define the headers with the bearer token
    const headers = {
      Authorization: `Bearer token=${token}`,
    };
    axios
      .get(url, { headers })
      .then((response) => console.log(response))
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  React.useImperativeHandle(ref, () => ({
    confirmFinishTaskChild() {
      confirmFinishTask();
    },
  }));

  function handleTaskClick() {
    if (props.task.status === "completed" && props.isBaseManager) {
      // Show reset confirmation prompt
      toggleResetConfirmation();
    } else if (props.task.status === "inprogress" && (props.user_id === props.task.worker || props.isAllowedMultipleTasks)) {
      toggleStopTask();
    }
  }

  return (
    <>
      <div
        className={`box-task ${props.task.name}   ${props.task.status === "inprogress" ? "task-active" : " "} ${props.task.status === "inprogress" && (props.user_id !== props.task.worker) ? "task-inprogress-byOther" : " "} ${props.task.status === "completed" ? "task-done" : " "
          }`}
      >
        <div className="task-name" onClick={handleTaskClick}>
          <div className="task">{t(props.task.name)}</div>
        </div>
        <div className="stateIcon">
          <i
            className={`bi ${props.task.status === "open" ? "bi-exclamation-triangle orange" : ""
              } ${props.task.status === "completed" ? "bi-check-square green" : ""} `}
          ></i>
        </div>
        <div className="worker">{props.task.worker_name}</div>

        <div className="status">{calculateTimeSinceStart()}</div>
        { (props.isToday || !isLiveEnv) && (
        <>
          {(((props.task.status === "open")) || (props.task.status === "inprogress" && (props.user_id === props.task.worker || props.isAllowedMultipleTasks))) && (
            <Button
            variant="success"
            size="lg"
            onClick={confirmFinishTask}
            type="submit"
            className={`status-action`}
            >
              {t(statusAction)}
            </Button>
          )}
        </>
      )}
      </div>

      {/* Shown if clicked on an active task */}
      {isStopTask && (
        <div className={`${props.task.name} dialog-box stop-task-dialog font-weight-bold`}>
          <div className="dialog-question stop-task-question">
            {t("task_aktion_abort_confirm")}{" "}
          </div>
          <div className="buttons">
            <Button variant="danger" size="lg" className="stop-task-no" onClick={toggleStopTask}>
              {t("no")}
            </Button>
            <Button variant="success" size="lg" className="stop-task-yes" onClick={StopTask}>
              {t("yes")}
            </Button>
          </div>
        </div>
      )}
      {/* Shown only if action button "fertig" is clicked */}
      {isFinishTask && (
        <div className={`${props.task.name} finish-task-dialog font-weight-bold`}>
          <Button variant="danger" size="lg" className="finish-task-no" onClick={toggleConfirmFinishTask}>
            {t("no")}
          </Button>
          <Button variant="success" size="lg" className="finish-task-yes" onClick={finishTask}>
            {t("sure")}
          </Button>
        </div>
      )}
      {/* Shown only if if clicked on a completed task */}
      {showResetConfirmation && (
        <div className={`${props.task.name} dialog-box reset-task-dialog font-weight-bold`}>
          <div className="dialog-question reset-task-question">
            {t("task_aktion_reset_confirm")}
          </div>
          <div className="buttons">
            <Button variant="danger" size="lg" className="reset-task-no" onClick={toggleResetConfirmation}>
              {t("no")}
            </Button>
            <Button variant="success" size="lg" className="reset-task-yes" onClick={resetTask}>
              {t("yes")}
            </Button>
          </div>
        </div>
      )}

{((((props.task.worker && props.user_id === props.task.worker) || props.isAllowedMultipleTasks) &&  props.task.name === "checkout" || props.task.name === "office" || props.task.name === "customer_on_site")) && props.task.status === "inprogress" && (
    <>
  {
  props.base === "heiligenhafen" && (
      <div className={`${props.task.name} pontoon-box`}>
        {!fremdstegChecked && (
          <div className="pontoon-dialog font-weight-bold">
            <div className="col pontoon">
              <label htmlFor="yachtPontoonSelect" className="form-label">
                {t("pontoon")}:{" "}
              </label>
              <select
                className="form-select"
                id="yachtPontoonSelect"
                value={yachtPontoon_real ? yachtPontoon_real : props.yacht_pontoon_real }
                onChange={(e) => setYachtPontoon_real(e.target.value)}
              >
                <option value=""></option>
                {["1b", "1c", "1d"].map((pontoon) => (
                  <option key={pontoon} value={pontoon}>
                    {pontoon}
                  </option>
                ))}
              </select>
            </div>
            <div className="col berth">
              <label htmlFor="yachtBerthSelect" className="form-label">
                {t("berth")}:{" "}
              </label>
              <select
                className="form-select"
                id="yachtBerthSelect"
                value={yachtBerth_real}
                onChange={(e) => setYachtBerth_real(e.target.value)}
              >
                <option value=""></option>
                {[...Array(40).keys()].map((num) => (
                  <option key={num + 1} value={num + 1}>
                    {num + 1}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div className="pontoon-dialog font-weight-bold">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="fremdstegCheckbox"
                checked={fremdstegChecked}
                onChange={() => {
                  setFremdstegChecked(!fremdstegChecked);
                  setYachtBerth_real("");
                  setYachtPontoon_real("");
                }}
                />
              <label
                htmlFor="fremdstegCheckbox"
                className={`${fremdstegChecked} form-check-label`}
                >
                {t("foreign_pontoon")}{" "}:
              </label>
            </div>
            {fremdstegChecked && (
              <>
                  <div className="col fremdsteg">
                <input
                  type="text"
                  className="form-control"
                  id="fremdstegInput"
                  value={yachtPontoon_real}
                  onChange={(e) => {
                    setYachtPontoon_real(e.target.value);
                  }}
                  />
              </div>
              
              <div className="col berth">

              <label
                className="form-label"
                >
                {t("berth")}:{" "}
              </label>
              <input
                type="text"
                className="form-control"
                id="berthInput"
                value={yachtBerth_real}
                onChange={(e) => {
                  setYachtBerth_real(e.target.value);
                }}
                />
            </div>
                </>
          )}
          </div>
    </div>
  )
}

{

 props.task.name ==="checkout" && (
   
   <div className="early-departure-box">
    <div className="early-departure-dialog font-weight-bold">
      <div className="early-departure-question">
        {t("client_early_departure_confirm")}
      </div>
      <label>
        <input
          type="radio"
          name="clientEarlyDeparture"
          checked={clientEarlyDeparture === false}
          onClick={() => {
            setClientEarlyDeparture(false);
            setShowDateTimePicker(false);
          }}
          />
        {t("no")}
      </label>
      <label>
        <input
          type="radio"
          name="clientEarlyDeparture"
          checked={clientEarlyDeparture === true}
          onClick={() => {
            setClientEarlyDeparture(true);
            setShowDateTimePicker(true);
          }}
          onChange={() => {}}
          />
        {t("yes")}
      </label>
    </div>
      {showDateTimePicker && (
        <div className="dateTimePicker">
          <div className="early-departure-question">
            {t("early_departure_infos")}
          </div>
          <DatePicker
            locale={i18n.language}
            selected={selectedDate ? selectedDate : new Date()}
            showTimeSelect
            timeIntervals={60}
            dateFormat="dd.MM.yyyy HH:00"
            onChange={(date) => {
              const selectedDateTime = new Date(date);
              console.log("Early departure Datepicker date: " + date);
              console.log("Early departure Datepicker selectedDateTime: " + selectedDateTime);
              setClientEarlyDepartureTime(selectedDateTime.getTime() / 1000); 
              setClientEarlyDepartureDate(selectedDateTime.getTime() / 1000); 
              setSelectedDate(selectedDateTime); 
            }}
            />
        </div>
      )}
  </div>
            )
           }
      </>
)}

      <div className="notification">
          {showWarning && !isTaskInProgressByOther && (
            <div className="warning-task-dialog font-weight-bold">
              <div className="warning-task-text">
                {t("info_other_task_inProgress")}
              </div>
              <div className="warning-task-ok" onClick={hideWarning}>
                {t("ok")}
              </div>
            </div>
          )}
        {isTaskForOtherWorker && (
          <div className="warning-task-dialog font-weight-bold">
            <div className="warning-task-text">
              {t("info_task_for_other_worker")}
            </div>
            <div className="warning-task-ok" onClick={hideWarning}>
              {t("ok")}{" "}
            </div>
          </div>
        )}
        {showCheckoutWarning && (
          <div className="warning-task-dialog font-weight-bold">
            <div className="warning-task-text">
              {t("checkout_warning_message")} {/* Replace with your actual translation key */}
            </div>
            <div className="warning-task-ok" onClick={() => setShowCheckoutWarning(false)}>
              {t("ok")}
            </div>
          </div>
        )}

      </div>
    </>
  );
});

export default Task;
