import React, { createContext, useState } from "react";

export const AppContext = createContext();

/*
 Context for states and variables which meant to be used as globale
 url: URL of the YACHA WebApp
 yachtInProgress: actual yacht been in progress
 isATaskAktive: true when a task is in progress
 */
const AppContextProvider = (props) => {
  let [yachaUrl, setYachaUrl] = useState(
    process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL: "https://staging-api.charterzentrum.de/v1"
  );
  let [yachtInProgress, setYachtInProgress] = useState(null);
  let [isATaskActive, setIsTaskActive] = useState(false);
  let [appLanguage, setAppLanguage] = useState(
    localStorage.getItem("AppLanguage")
  );
  let [lastEventTime, setLasEventTime] = useState(null)

  // True if the deployment is on LIVE, otherwise false
  let [isLiveEnv, setIsLiveEnv] = useState(
    process.env.REACT_APP_API_URL == "https://api.charterzentrum.de/v1"
  );

  // Functions that will be called from other component to update the context
  const updateYachtInProgress = (yacht_id) => {
    setYachtInProgress(yacht_id);
  };

  const updateIsTaskActive = (IsTaskActive) => {
    setIsTaskActive(IsTaskActive);
  };

  const updateAppLanguage = (appLanguage) => {
    setAppLanguage(appLanguage);
  };

  const updateLastEventTime = (lastEventTime) => {
    setLasEventTime(lastEventTime);
  }
  return (
    <AppContext.Provider
      value={{
        yachaUrl,
        yachtInProgress,
        isATaskActive,
        appLanguage,
        lastEventTime,
        isLiveEnv,
        updateIsTaskActive,
        updateYachtInProgress,
        updateAppLanguage,
        updateLastEventTime,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
