import React, { useState, useEffect, useContext, useRef } from "react";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Badge } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {FcHighPriority, FcBusinessman } from "react-icons/fc";
import {ImMan, ImCalendar} from "react-icons/im"
import {AiFillPhone} from "react-icons/ai"
import {FaSignInAlt, FaSignOutAlt, FaUserCheck, FaInfoCircle } from "react-icons/fa"
import { MdClose } from "react-icons/md";


import { AppContext } from "../contexts/AppContext";

import yachaIcon from "../assets/yacha-icon.png";
import ecosailIcon from "../assets/ecosail-icon.png";

import Task from "./Task";
import "../App.css";

let isAnotherTaskOpen = false;

function Yacht(props) {
  // Access global states and variables from context
  const {
    yachaUrl,
    yachtInProgress,
    isATaskActive,
    updateYachtInProgress,
  } = useContext(AppContext);

  const { t } = useTranslation();

  /* Create a Ref to  tasks ( child of yacht).
  This way Yacht can call functions from tasks to perform actions.*/
  const TaskRef = useRef();

  /* states to control in which states components/tasks are in. 
  Used to render components dynamically*/
  let [user_id, setUser_id] = useState(props.user_id);
  let [yacht_id, setYacht_id] = useState(props.yacht_id);
  let [isCardOpen, setIsCardOpen] = useState(props.isCardOpen);
  let [isTaskDone, setIsTaskDone] = useState(
    props.status === "completed" ? true : false
  );
  let [isTaskActive, setIsTaskActive] = useState(false);
  let [isTaskTodo, setIsTaskTodo] = useState(props.isTaskTodo);

  let [isTaskInProgressByOther, setIsTaskInProgressByOther] = useState(
    props.isTaskInProgressByOther
  );
  let [statusAction, setStatusAction] = useState(props.statusAction);
  let [timeSinceStart, setTimeSinceStart] = useState(props.time);
  let [buttonType, setButtonType] = useState(props.buttonType);
  let [showWarning, setShowWarning] = useState(false);
  let [hasMultipleTasks, setHasMultipleTasks] = useState(
    props.hasMultipleTasks
  );
  let [isYachtInProgress, setIsYachtInProgress] = useState(yachtInProgress == props.yacht_id)

  const [order, setOrder] = useState(0);

  /*
  To calculate time since a task has been started
  Update evry minute
  */
 const MINUTE_MS = 60000;
 let [taskMinutes, setTaskMinutes] = useState(1);
 useEffect(() => {
   if (isTaskActive) {
     const interval = setInterval(() => {
       setTaskMinutes((prevTaskMinutes) => prevTaskMinutes + 1);
       if (isTaskActive) {
         setTimeSinceStart(
           ` ${t("task_inProgress_since")} ${taskMinutes} ${taskMinutes > 1 ? t("minutes") : t("minute")
          }`
          );
        }
      }, MINUTE_MS);
      // Clear interval to prevent memory leaks.
      return () => clearInterval(interval);
    }
  }, [timeSinceStart]);
  
  // Yachts in progress first
  useEffect(() => {
    if (props.status === "inprogress") setOrder(-2);
    if (props.hasFixedWorkerForUser && props.status !== "inprogress") setOrder(-1)
  }, []);

  useEffect(() => {
    if (!yachtInProgress  && !yachtInProgress === yacht_id) {
      setIsCardOpen(true);
    }
    if (props.status === "completed") {
      setStatusAction("task_status_completed");
    }
  }, [isATaskActive, isYachtInProgress]);
  
  const IsClientHere = () => {
    if (props.customer_on_site === "1") return true
    
    for (const key in props.tasks) {
      const task = props.tasks[key];     
      if ((task.name === "office" || task.name === "customer_on_site") && task.status === "completed") {
        return true;
      }
    }
    return false;
  }
  
     
  
  /* Functions to handel events and states changes. */
  // open/close tasks cards
  function toggleCard(e) {
    e.preventDefault();
    if (props.yachtState === "doingByOther") {
      return;
    }
    if (hasMultipleTasks && !isTaskDone) {
      setStatusAction(t("yacht_status_open"));
      setButtonType("btn-success");
      if (yachtInProgress === yacht_id) {
        setIsCardOpen(true);
        return;
      }
    }

    setIsCardOpen(!isCardOpen);
  }

  // What should happen if an action (start, finish..) button is clicked
  function confirmFinishTask(e) {
    if (localStorage.getItem("AppOffline") === "true") {
      return;
    }
    if (!hasMultipleTasks) {
      // Yacht has only one task => Call the tasks function to handel this action
      // Dont trigger parents to handel event.
      console.log("Yacht - worker: " + props.yacht_id + " " + props.tasks["1"].worker)
      if (props.status === "inprogress" && props.user_id !== props.tasks["1"].worker) 
        return

      TaskRef.current.confirmFinishTaskChild();
      e.stopPropagation();
    }
  }

    // Function to format a date to "dd.mm.yyyy" format
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if single digit
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  // Function to calculate the difference to departure day in days
  function calculateDaysUntil(departureTimestamp) {
    const differenceInTime = departureTimestamp - props.selectedDate;
    const differenceInDays = Math.ceil(differenceInTime / (3600 * 24));
  
    if (differenceInDays === 1) {
      return 1;
    } else {
      return differenceInDays;
    }
  }

  const daysUntilDeparture = props.departure && props.departure !== "0" ? calculateDaysUntil(props.departure) : '';

    // Function to calculate the color of the reurning and outgoing icons
    function getIconColor() {
      const differenceInTime = props.departure - props.selectedDate;
      const differenceInDays = Math.ceil(differenceInTime / (3600 * 24));
    
      if (props.area === "baltic") {
        if (props.task_type === "returning") {
            if (differenceInDays === 1) {
                return props.priority === "1" ? "red" : "orange";
            } else {
                return "gray";
            }
        } else if (props.task_type === "outgoing") {
            return props.priority_prev === "1" ? "red" : "orange";
        } 
      } else if (props.area === "mallorca" || props.area === "croatia") {
          if (props.task_type === "returning") {
              if (differenceInDays === 1) {
                  return props.priority_next === "1" ? "red" : "orange";
              } else {
                  return "gray";
              }
          } else if (props.task_type === "outgoing") {
                  return props.priority === "1" ? "red" : "orange";

          } 
      } 
    }
      

    const iconTypeTaskColor = getIconColor();

  // Start rendering
  return (
    <div className={`yacht-wrapper ${props.hasFixedWorkerForUser && !props.isBaseManager? "hasFixedWorkerForUser":" " } ${props.status} ${props.shifted === "1" ? "shifted" : " "
  } ${props.status === "inprogress" ? "task-active" : " " 
} ${props.priority === "1" && props.area === "baltic" ? "prio" : ""}`} style={{ order: order }}
>
    {props.shifted === "1"  &&
    <>
       <div className="shifted-day">
        <p>{t("shifted-tasks-original-day", {date: formatDate(props.task_original_day)})}</p>
      </div>
    </>
    }
    {!props.isShiftedHidden && 

    <div
    className={`card-container ${props.status === "inprogress" ? "task-active" : " "
  } ${hasMultipleTasks ? "multiple-tasks" : "single-task"}`}
  >
      <Card
        className={`yacht card ${isCardOpen ? "card-open" : "card-closed"} ${props.status === "completed" ? "task-done" : " "
      } ${props.status === "inprogress"
      ? "task-active"
      : " "
    } ${isTaskTodo ? "todo" : " "} ${props.isTaskTodo} ${isTaskInProgressByOther ? "doingByOther" : " "
  } ${props.isTaskTodo}`}
  >
        <Card.Header
          className="yachtname"
          onClick={toggleCard}
          style={{ cursor: "pointer" }}
          >
          <div className="box-yachtname">
            <div className="yachtname font-weight-bold">
              {props.brand === "yacha" && <img src={yachaIcon} className="yacha-icon" alt="yacha" />}
              {props.brand === "eco" && <img src={ecosailIcon} className="yacha-icon" alt="ecosail" />}
              {props.name} {props.area !== "baltic" && props.client_arrival && props.client_arrival !== "0" ? "(" + new Date(
              props.client_arrival * 1000
              ).toLocaleString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })+")" : ""}
            </div>
              <div className="pontoon-early-departure">
                <div className="early-departure">
                  {props.client_early_departure === "1" && props.client_departure_date !== "0" && (
                    <span>
                      {(() => {
                        const departureDate = new Date(props.client_departure_date * 1000);
                        const day = departureDate.getDate().toString().padStart(2, '0');
                        const month = (departureDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed

                        // Format the date as dd.mm.
                        let formattedDate = `${day}.${month}.`;

                        // If there's a departure time, add it to the formatted date
                        if (props.client_departure_time) {
                          const formattedTime = new Date(props.client_departure_time * 1000).toLocaleString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                          });
                          formattedDate += `  ${formattedTime}`;
                        }

                        return formattedDate;
                      })()}
                    </span>

                  )}
                </div>
                <div className="pontoon">
                  <span>
                    {props.yacht_pontoon_real} {props.yacht_berth_real}
                  </span>
                </div>
              </div>
                <div className="client-arrival-time">
                {props.area === "baltic" && props.task_type === "outgoing" && props.client_arrival && props.client_arrival !== "0" ? "(" + new Date(
                props.client_arrival * 1000
                ).toLocaleString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })+")" : ""}
                </div>

            <div className="yacht-icons">
              {/* <div className="icon-container">
              { IsClientHere() && (                
                  <FaUserCheck className="fa-icon green"/>
              )}
              </div> */}
              {/* <div className="icon-container">
                {props.priority === "1" && (
                    <FcHighPriority className="fa-icon red"/>
                )}
              </div> */}
                <div className="icon-container">
                {props.base_info !== ""  && (
                  <FaInfoCircle className={`fa-icon fa-gray`}/>               
                )}
              </div>
              {!isCardOpen && props.isToday && (hasMultipleTasks || (!hasMultipleTasks && props.tasks["1"].status !== "completed")) && (
                <>
                  <Button
                    onClick={
                      hasMultipleTasks && !isCardOpen
                      ? toggleCard
                      : confirmFinishTask
                    }
                    type="submit"
                    className={`yacht-state btn  ${hasMultipleTasks && !isTaskDone && !isCardOpen
                      ? "btn-open"
                      : buttonType
                    } font-weight-bold`}
                    >
                    {!hasMultipleTasks &&
                      props.status === "inprogress" && props.user_id === props.tasks["1"].worker &&
                      t("task_action_finish")}
                    {!hasMultipleTasks &&
                      props.status === "inprogress" && props.user_id !== props.tasks["1"].worker &&
                      t("task_in_progress")}
                    {!hasMultipleTasks &&
                      props.status === "open" && props.tasks["1"].status !== "completed" &&
                      t("task_action_start")}
                    {!hasMultipleTasks &&
                      props.status === "completed" &&
                      t("yacht_status_completed")}
                    {hasMultipleTasks &&
                      `${props.status === "completed"
                      ? t("yacht_status_completed")
                      : t(statusAction)
                    }`}
                  </Button>
                </>
              )}
              {
                  <div className={`icon-container  ${iconTypeTaskColor}`}>
                  {
                    props.task_type === "returning" && <FaSignInAlt className="fa-task-type returning"/>            
                  }
                  {
                    props.task_type === "outgoing" && <FaSignOutAlt className="fa-task-type outgoing"/>            
                  }
                </div>
              }
              {isCardOpen && <div className="close"><MdClose /></div>}
          </div>
          </div>
        </Card.Header>
        <div className="tasks-list">
          {Object.entries(props.tasks)
            .filter(([taskId, task]) => {
              // Retrieve and parse the tasks array from localStorage
              const storedTaskNames = JSON.parse(localStorage.getItem("tasks")) || [];
              // Check if the current task's name is in the stored task names array
              return storedTaskNames.includes(task.name);
            })
            .map(([taskId, task]) => (
              <Task
                key={task.task_item_id}
                task={task}
                isCardOpen={isCardOpen}
                yacht_id={yacht_id}
                ref={TaskRef}
                user_id={user_id}
                setYachtStatus={setStatusAction}
                setYachtButtonType={setButtonType}
                isToday={props.isToday}
                isAllowedMultipleTasks={props.isAllowedMultipleTasks}
                isRunner={props.isRunner}
                isBaseManager={props.isBaseManager}
                isBoatman={props.isBoatman}
                getTasks={props.getTasks}
                yacht_pontoon_real={props.yacht_pontoon_real}
                yacht_berth_real={props.yacht_berth_real}
                base={props.base}
              />
            ))}
        </div>


        {isCardOpen && ((props.yacht_info !== "" || props.base_info !== "" || props.client_arrival !== "0" || props.customer_crew !== null || props.customer_duration !== "0" || props.customer_name !== "" || props.customer_phone !== "") ||  (props.runner_task_list !== "" && (props.isRunner || props.isBaseManager))) && (
          <Card.Body>
            <div className="details-view">
              <div className="yacht-infos">
                <div className="yacht-length"> {props.yacht_info}</div>
                {/* <div className="yacht-length">
                  {`${t("yacht_length")}: ${props.yacht_length}m - `}
                  {props.yacht_toilets}{" "}
                  {props.yacht_toilets > 1 ? t("toilets") : t("toilet")}
                </div> */}
                {props.base_info && (
                  <div className="marks base_info">
                    <span className="bold">{t("base_info")}</span> {props.base_info}
                  </div>
                )}
                {props.client_arrival !== "0" && (
                  <div className="marks">
                    <span className="bold">{t("yacht_info_customer_arrival_time")}</span> {new Date(
                      props.client_arrival * 1000
                      ).toLocaleString([], {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                  </div>
                )}
                {props.client_arrival_info && props.base !== "can-pastilla" &&  (props.isShuttleService || props.isBaseManager) && (
                  <>
                    <div className="marks client_arrival_info">
                    <span className="bold">{t("shuttleservice_infos")} - {t(props.client_arrival_transfer_type)}</span>
                    </div>
                    {props.client_arrival !== "0" && (
                    <div className="marks client_arrival_info">
                      <span className="bold">{t("client_arrival_transfer")}</span> {new Date(
                      props.client_arrival_transfer * 1000
                      ).toLocaleString([], {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </div>
                    )}
                    <div className="marks client_arrival_info">
                      <span className="bold">{t("client_arrival_info_demands")}</span> {t("bus/train")} {props.client_arrival_info}. {props.client_arrival_demands}
                    </div>
                  </>
                )}
                {Object.keys(props.runner_task_list).length > 0 && (props.isRunner || props.isBaseManager) && (
                  <div className="marks runner_tasks_info">
                    <span className="bold">{t("runner_tasks_info")}</span>
                    {props.task_type === "returning" ? t("get_from_yacht") : t("bring_to_yacht")}
                    {Object.keys(props.runner_task_list).map((key, index, array) => (
                      <span key={key}>
                        <span> {props.runner_task_list[key].count}x {t(`runner_task_id_${key}`)}</span>
                        {index !== array.length - 1 && <span>, </span>}
                      </span>
                    ))}
                  </div>
                )}


                {(props.customer_name !== "" || props.customer_phone !== "" ) && (props.isBaseManager || props.isOffice || props.isTechnician) &&  (
                  <div className="marks customer">
                    {props.customer_name !== "" && (
                      <span><FcBusinessman /> : {props.customer_name}</span>
                    )}
                    {props.customer_phone !== "" && (
                     <span><AiFillPhone /> : <a href={`tel:${props.customer_phone}`}> {props.customer_phone}</a></span>
                    )}
                  </div>
                  )}
                {(props.customer_crew !== null || props.customer_duration !== "0" || props.departure !== "0") &&  (
                  <div className="marks customer">
                    {props.customer_crew !== null && (
                      <span><ImMan /> : {props.customer_crew}</span>
                    )}
                    {props.customer_duration !== "0" && (
                      <span><ImCalendar /> : {props.customer_duration}</span>
                    )}
                     {props.departure &&  props.departure !== "0" && (
                    <div className="departure-info">
                      <span className="departure-info bold">{t("next_charter")}</span>{" "}
                      {daysUntilDeparture === 1 ? t("tomorrow") : t("days", { count: daysUntilDeparture })}{". "} {props.priority === "1" ? t("customer_arrives_today") : ""}
                    </div>
                )}
                  </div>
                )}
              </div>
              
            </div>
          </Card.Body>
        )}

        <div className="yacht-progress">
          <div className="progress">
            {/* Render task icons based on their status */}
            {Object.values(props.tasks)
              .sort((a, b) => {
                if (a.name === 'customer_on_site') return -1;
                if (b.name === 'customer_on_site') return 1;
                return 0;
              })
              .map((task) => (
                <div key={task.id} className="progressIcon">
                  <img
                    src={`assets/icons/${task.name}-${task.status}.svg`}
                    alt={t(task.name)}
                    title={t(task.name)}  
                    style={{
                      width: '32px',
                      height: '32px',
                    }}
                  />
                </div>
              ))}
          </div>
        </div>

      </Card>

    </div>
      }
        </div>
  );
}

export default Yacht;
