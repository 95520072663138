import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const fallbackLng = ["de"];
const availableLanguages = ["de", "en", "es", "hr", "pl", "ru", "ua"];

i18n
  .use(Backend) // load translations
  .use(LanguageDetector) // detect user language
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/translations.json",
    },
    fallbackLng: "de",

    detection: {
      checkWhitelist: true,
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
