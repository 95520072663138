import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./i18nextConf";

//const root = ReactDOM.createRoot(document.getElementById("root"));
ReactDOM.render(
  // <React.StrictMode>
  <App />,
  document.getElementById("root")
);
