import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import 'react-calendar/dist/Calendar.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { MdHome, MdHomeFilled, MdNavigateBefore } from "react-icons/md";
import { MdNavigateNext } from "react-icons/md";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Spinner from 'react-bootstrap/Spinner';

import User from "../components/User";
import Yacht from "../components/Yacht";
import YachtNavbar from "../components/Navbar";
import Login from "./login";

import { AppContext } from "../contexts/AppContext";

import "../App.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import { enUS, de, es, ru, hr, pl} from 'date-fns/locale/';
registerLocale('en-US', enUS)
registerLocale('de', de)
registerLocale('es', es)
registerLocale('ru', ru)
registerLocale('hr', hr)
registerLocale('pl', pl)
// registerLocale('ua', ua) Ukrainian not supported yet
/*
Tasks page
- Display user informations (name, team, base) and all his tasks
- Every yacht is passed to Yacht.js
- Every task for a given yacht is passed in Yacht.js to Task.js
- Tasks are manegd in Task.js
*/
function Tasks() {
  // Access global states and variables from context
  const {
    yachaUrl,
    yachtInProgress,
    isATaskActive,
    updateIsTaskActive,
    updateYachtInProgress,
    appLanguage,
    updateAppLanguage,
    lastEventTime,
    updateLastEventTime,
  } = useContext(AppContext);

  const { t, i18n } = useTranslation();
  // Get users token.
  let token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : sessionStorage.getItem("token");

  // Build the requests url as defined by the APi
  let user_id = localStorage.getItem("user_id")
    ? localStorage.getItem("user_id")
    : sessionStorage.getItem("user_id");
    let bearer_token = "Bearer token=" + token;
    let [userTasks, setUserTasks] = useState([]);
  let [noTasks, setNoTasks] = useState(null);

  let [isInvalidToken, setIsIvalidToken] = useState(false);
  let [isError, setIsError] = useState(false);
  let [errorMessage, setErrorMessage] = useState(null);



  // To manage selected value in calendar
  let [today, setToday] = useState(new Date());
  today.setHours(4);

  let [startDate, setStartDate] = useState(new Date());
  // startDate.setHours(4, 0, 0);
  let [selectedDate, setSelectedDate] = useState(new Date().getTime() / 1000);
  let [selectedDateFormated, setSelectedDateFormated] = useState(new Date());

  let isToday = today.toLocaleDateString() == selectedDateFormated.toLocaleDateString() ? true : false;
  let selectedBase = localStorage.getItem("selectedBase") || localStorage.getItem("defaultBase") || "[]";

  const [isLoading, setIsLoading] = useState(false); // Loading state


  console.log("SelectedBase is: " + selectedBase);

  let url = `${yachaUrl}/yacha/checklist/list/?user_id=${user_id}&day=${Math.floor(selectedDate)}&base=${selectedBase}`;

  function formatTime() {
    const now = new Date();

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
}

console.log("Update data: ", "[" + formatTime() + "] " + url);

  // If token doesn't exist user is redirected to the login page
  // otherwise his tasks are fetched from the API
  const navigate = useNavigate();
  let [isAppOffline, setIsAppOffline] = useState(
    localStorage.getItem("AppOffline")
  );

  const MINUTE_MS = 6000;
  useEffect(() => {
    if (token) {
      i18n.changeLanguage(localStorage.getItem("language").toLowerCase());
    }
  }, []);

  const [enabledDays, setEnabledDays] = useState([]);

  const handleMonthChange = (newDate) => {
    // Here, you can trigger any actions or API calls related to the new month
    // For example, fetch task data for the new month and update the enabledDays array
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const headers = {
      Authorization: `Bearer token=${token}`,
    };

    let url = `${yachaUrl}/yacha/checklist/list/?user_id=${user_id}&month=${month}&year=${year}&base=${selectedBase}`
    console.log("handleMonthChange URL: " + url)
    axios.get(url, { headers })
      .then(response => {
        const monthData = response.data;

        const enabledDaysArray = [];
        for (const [day, value] of Object.entries(monthData)) {
          if (value === 1) {
            enabledDaysArray.push(new Date(year, month - 1, parseInt(day, 10)));
          }
        }

        setEnabledDays(enabledDaysArray);
        setErrorMessage(null)
        setIsError(false)
      })
      .catch(error => {
        setIsError(true)
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setErrorMessage( error.response.status)
          console.error('1. Error fetching task data:', error.response.status, error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          setErrorMessage(error.request)
          console.error('Error fetching task data: No response received', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          setErrorMessage(error.message)
          console.error('Error fetching task data:', error.message);
        }
        // console.error('Error fetching task data:', error.status);
      });
  };

  useEffect(() => {
    if (startDate) {
      handleMonthChange(startDate);
    }
  }, [startDate]);

  const isEnabledDay = (date) => {
  // Check if the date is in the enabledDays array
    const isDateInEnabledDays = enabledDays.some((enabledDate) =>
      date.getDate() === enabledDate.getDate() &&
      date.getMonth() === enabledDate.getMonth() &&
      date.getFullYear() === enabledDate.getFullYear()
    );
    // Workaround to enable todays button
    // Check if the date is today's date
    const today = new Date();
    const isToday = date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    // Return true if the date is in enabledDays or if it's today
    return isDateInEnabledDays || isToday;
    };


  useEffect(() => {
    const interval = setInterval(() => {
      setIsAppOffline(localStorage.getItem("AppOffline"));
    }, MINUTE_MS);
    // Clear interval to prevent memory leaks.
    return () => clearInterval(interval);
  }, [isAppOffline, appLanguage]);

  useEffect(() => {
    const fetchData = () => {
      if (token) {
        getTasks();
      } else {
        navigate("/");
      }
    };
  
    // Fetch data immediately when dependencies change
    fetchData();
  
    // Fetch tasks every 10 seconds
    const interval = setInterval(() => {
      fetchData();
    }, 10000);
  
    // Clear interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, [token, isATaskActive, isAppOffline, selectedDate, lastEventTime, selectedBase]);

    // To change format in datepiker depending on screen size
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [dateFormat, setDateFormat] = useState("eeee, dd.MM.yyyy");
    
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    useEffect(() => {
      // Set the date format based on window width
      if (windowWidth <= 768) {
        setDateFormat("eee, dd.MM.yyyy");
      } else {
        setDateFormat("eeee, dd.MM.yyyy");
      }
    }, [windowWidth]);
  



   // Users tasks as Array
   const userTasksArray = Object.entries(userTasks);

  // Retrieve and parse the tasks array from localStorage
  let storedTaskNames = [];
  try {
    const tasksString = localStorage.getItem("tasks");
    if (tasksString && tasksString !== "undefined") {
      storedTaskNames = JSON.parse(tasksString);
    }
  } catch (e) {
    console.error("Failed to parse tasks from localStorage", e);
    setIsError(true)
    storedTaskNames = [];
  }
    // Check if any task name of a yacht is in the stored task names
   const hasMatchingTask = (yacht) => {
      return Object.values(yacht.tasks).some(task => storedTaskNames.includes(task.name));
    };

   const shiftedYachts = userTasksArray.filter(([key, yacht]) => 
      yacht.shifted === "1" && hasMatchingTask(yacht)
    );

   const nonShiftedYachts = userTasksArray.filter(([key, yacht]) => 
      yacht.shifted === "0" && hasMatchingTask(yacht)
    );

   const openShiftedYachts = userTasksArray.filter(([key, yacht]) => 
      yacht.shifted === "1" && yacht.status !== "completed" && hasMatchingTask(yacht)
    );

   const [executionCount, setExecutionCount] = useState(0);

   useEffect(() => {
     // Check if the effect has executed twice
     if (executionCount < 2) {
       setIsShiftedHidden(isShiftedHidden && openShiftedYachts.length === 0);
       setInputIcon(isShiftedHidden && openShiftedYachts.length === 0 ? <FaEyeSlash /> : <FaEye />);
       setExecutionCount(executionCount + 1);
     }
   }, [userTasks]);

  // Get users tasks from the API
  const getTasks = async () => {
    // Define the headers with the bearer token
    const headers = {
      Authorization: `Bearer token=${token}`,
    };
    setIsLoading(true);
    axios
      .get(url, { headers })
      .then((res) => {
        setIsLoading(false);
        localStorage.setItem("BaseChanged", "false")
        if (res.data == 401) {
          setIsIvalidToken(true)
        }
         else if (res.data === false) {
          setNoTasks(true);
          console.log("NoTask is true: " + { noTasks });
          console.log("URL: " + url)
          return;
        } else {
          setIsIvalidToken(false);
          setUserTasks(res.data);
          setNoTasks(false);
        }
        setErrorMessage(null)
        setIsError(false)
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true)
        setErrorMessage(err.status)
        console.log(err);
      });
  };
  
  
    // Which roles the user has are retrieved and passed to User.js to be rendered
    const userRoles = localStorage.getItem("role")
    ? JSON.parse(localStorage.getItem("role"))
    : JSON.parse(sessionStorage.getItem("role"));

  // TODO: NOW JUST for tests purposes only
  // untill implementing multilanguage support
  // Than it should be dynamically
  const isAdmin = userRoles && Object.entries(userRoles)
  .find(([key, role]) => key === 'admin' && role === 1);

  const isBaseManager = userRoles && Object.entries(userRoles)
    .find(([key, role]) => key === 'basemanager' && role === 1);
  
  const isOffice = userRoles && Object.entries(userRoles)
    .find(([key, role]) => key === 'office' && role === 1);

  const isRunner = userRoles && Object.entries(userRoles)
    .find(([key, role]) => key === 'runner' && role === 1);

  const isTechnician = userRoles && Object.entries(userRoles)
    .find(([key, role]) => key === 'technician' && role === 1);

  const isShuttleService = userRoles && Object.entries(userRoles)
    .find(([key, role]) => key === 'shuttleservice' && role === 1);

    const isBoatman = userRoles && Object.entries(userRoles)
    .find(([key, role]) => key === 'boatman' && role === 1);



  const isAllowedMultipleTasks = userRoles && Boolean(
      Object.entries(userRoles).find(
        ([key, role]) => (key === 'basemanager' || key === 'supervisor_cleaner' || key === 'supervisor_boatwasher' || key === 'supervisor_boatman' || key === 'boatman' || key === 'supervisor_cleanse') && role === 1  )
    );

  /*
  For every yacht the users tasks will be rendered
  */
  const renderYachtTasks = (yachts, isShifted = false) => {
 // Check if base is "heilighafen" before sorting
  const base = localStorage.getItem("selectedBaseText") || localStorage.getItem("defaultBaseText");
  const shouldSort = base === "Heiligenhafen";
  console.log("### Base: " + base)
  if (shouldSort) {
    // Sort yachts array based on brand
    yachts = yachts.sort((a, b) => {
      const brandA = a[1].brand;
      const brandB = b[1].brand;

      if (brandA === "yacha" && brandB !== "yacha") {
        return -1;
      }
      if (brandA !== "yacha" && brandB === "yacha") {
        return 1;
      }
      // Default sorting for other cases or when both are "yacha" or "eco"
      return brandA.localeCompare(brandB);
    });
  }

  let sortedYachts = yachts;

  // Additional sorting by "area," "length" and "prio"
  // Check if the "area" is "baltic" for sorting
  if (sortedYachts.some(([key, yacht]) => yacht.area === "baltic")) {
    sortedYachts = sortedYachts
      .filter(([key, yacht]) => yacht.area === "baltic")
      .sort((a, b) => {
        // First, sort by length
        if (a[1].yacht_length !== b[1].yacht_length) {
          // Sort by length from small to big
          return a[1].yacht_length - b[1].yacht_length; 
        }

        // If lengths are equal, sort by priority
        return b[1].priority - a[1].priority;
      });
  }

  // Additional sorting by "area," "arrival time" and "prio"
  // Check if the "area" is "mediterranean" for sorting
  if (sortedYachts.some(([key, yacht]) => yacht.area === "mallorca" || yacht.area === "croatia")) {
    sortedYachts = sortedYachts
      .filter(([key, yacht]) => yacht.area === "mallorca" || yacht.area === "croatia")
      .sort((a, b) => {
        if (a[1].priority === b[1].priority) {
        // Sort by client_arrival value
        const clientArrivalA = parseInt(a[1].client_arrival);
        const clientArrivalB = parseInt(b[1].client_arrival);

        if (clientArrivalA !== 0 && clientArrivalB === 0) {
          return -1;
        } else if (clientArrivalA === 0 && clientArrivalB !== 0) {
          return 1;
        } else {
          return clientArrivalA - clientArrivalB;
        }        
      }
        // If lengths are equal, sort by priority
        return b[1].priority - a[1].priority;
      });
  }


  // Check if all task status are completed

    return sortedYachts.map(([key, yacht]) => {

      const statusAction = () => {
        if (yacht.status === "completed") {
          return "yacht_status_completed";
        }
        return Object.entries(yacht.tasks).length > 1
          ? "yacht_status_open"
          : "task_action_start";
      };
  
      return (
        <Yacht
          key={yacht.task_id}
          task_type={yacht.task_type}
          yacht_id={yacht.yacht_id}
          task_original_day={yacht.task_original_day}
          shifted={yacht.shifted}
          user_id={user_id}
          name={yacht.name}
          type={yacht.type}
          base={selectedBase}
          area={yacht.area}
          status={yacht.status}
          yachtStatus={yacht.status}
          taskState={yacht.status}
          isTaskActive={yacht.status === "in_progress"}
          yachtInfo={yacht.yacht_info}
          isCardOpen={isShifted ? yacht.status === "inprogress" : false}
          isTaskOpen={yacht.status === "open"}
          buttonType={"btn-success"}
          time=""
          priority={yacht.priority}
          priority_next={yacht.priority_next}
          priority_prev={yacht.priority_prev}
          statusAction={statusAction}
          hasMultipleTasks={Object.entries(yacht.tasks).length > 1}
          yacht_length={yacht.yacht_length}
          yacht_toilets={yacht.yacht_toilets}
          yacht_info={yacht.yacht_info}
          base_info={yacht.base_info}
          client_arrival={yacht.client_arrival}
          client_arrival_info={yacht.client_arrival_info}
          client_arrival_demands={yacht.client_arrival_demands}
          client_arrival_transfer={yacht.client_arrival_transfer}
          client_arrival_transfer_type={yacht.client_arrival_transfer_type}
          client_early_departure={yacht.client_early_departure}
          client_departure_date={yacht.client_departure_date}
          client_departure_time={yacht.client_departure_time}
          runner_task_list={yacht.runner_task_list}
          tasks={yacht.tasks}
          isToday={isToday}
          brand={yacht.brand}
          isShiftedHidden={isShiftedHidden}
          isAllowedMultipleTasks={isAllowedMultipleTasks}
          getTasks={getTasks}
          isOffice={isOffice}
          isRunner={isRunner}
          isBaseManager={isBaseManager}
          isTechnician={isTechnician}
          isShuttleService={isShuttleService}
          isBoatman={isBoatman}
          customer_crew={yacht.customer_crew}
          customer_duration={yacht.customer_duration}
          customer_name={yacht.customer_name}
          customer_phone={yacht.customer_phone}
          customer_on_site={yacht.customer_on_site}
          yacht_pontoon_real={yacht.pontoon_real}
          yacht_berth_real={yacht.berth_real}
          departure={yacht.departure}
          selectedDate={selectedDate}
          hasFixedWorkerForUser = {Object.values(yacht.tasks).some(task => {
            return task.fixed_worker === "1" && task.worker === user_id;
          })}
        />
      );
    });
  };
  
  const renderedUserNonShiftedTasks = renderYachtTasks(nonShiftedYachts);
  const renderedUserShiftedTasks = renderYachtTasks(shiftedYachts, true);
  
  const getUserRoles = userRoles && !isBaseManager && (() => {
    const supervisorRoles = [];
    let supervisorLabel = "";
    const otherRoles = [];
    const supervisorRoleSet = new Set();
  
    Object.entries(userRoles).forEach(([key, role]) => {
      if (role === 1) {
        switch (key) {
          case "supervisor_cleaner":
            supervisorRoles.push(t("team_role_cleaner"));
            supervisorRoleSet.add("cleaner");
            supervisorLabel = "team_role_supervisor_cleaner";
            break;
          case "supervisor_boatwasher":
            supervisorRoles.push(t("team_role_boatwasher"));
            supervisorRoleSet.add("boatwasher");
            supervisorLabel = "team_role_supervisor_boatwasher";
            break;
          case "supervisor_boatman":
            supervisorRoles.push(t("team_role_boatman"));
            supervisorRoleSet.add("boatman");
            supervisorLabel = "team_role_supervisor_boatman";
            break;
          case "supervisor_cleanse":
            supervisorRoles.push(t("team_role_cleanse"));
            supervisorRoleSet.add("boatwasher");
            supervisorRoleSet.add("cleaner");
            supervisorLabel = "team_role_supervisor_cleanse";


            break;
          default:
            switch (key) {
              case "diver":
                otherRoles.push(<span key='diver' className="role">{t("team_role_diver")}</span>);
                break;
              case "cleaner":
                otherRoles.push(<span key='cleaner' className="role">{t("team_role_cleaner")}</span>);
                break;
              case "boatman":
                otherRoles.push(<span key='boatman' className="role">{t("team_role_boatman")}</span>);
                break;
              case "boatwasher":
                otherRoles.push(<span key='boatwasher' className="role">{t("team_role_boatwasher")}</span>);
                break;
              case "technician":
                otherRoles.push(<span key='technician' className="role">{t("team_role_technician")}</span>);
                break;
              case "shuttleservice":
                otherRoles.push(<span key='shuttleservice' className="role">{t("team_role_shuttleservice")}</span>);
                break;
              case "office":
                otherRoles.push(<span key='office' className="role">{t("team_role_office")}</span>);
                break;
              case "runner":
                otherRoles.push(<span key='runner' className="role">{t("team_role_runner")}</span>);
                break;
              default:
                break;
            }
            break;
        }
      }
    });
  
    if (supervisorRoles.length > 0) {
      otherRoles.unshift(
        <span key='supervisor' className="role">
          {t(supervisorLabel)} ({supervisorRoles.join(", ")})
        </span>
      );
    }
  
    // Remove roles from otherRoles that are in supervisorRoleSet
    const filteredOtherRoles = otherRoles.filter(roleElement => {
      const roleKey = roleElement.key;
      return !supervisorRoleSet.has(roleKey);
    });
  
    return filteredOtherRoles;
  })();
  

  function updateDate(date) {
    date.setHours(4)
    setStartDate(date)
    setSelectedDateFormated(date);
    setSelectedDate(date.getTime() / 1000)
  };

  function getPreviousDay(date) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
  }

  function getNextDay(date) {
    const next = new Date(date.getTime());
    next.setDate(date.getDate() + 1);

    return next;
  }

    // Toggle shifted tasks to show or hide it
let [isShiftedHidden, setIsShiftedHidden] = useState(true);
let [inputIcon, setInputIcon] = useState(isShiftedHidden ? <FaEye /> : <FaEyeSlash />);

function hideShowShifted(e) {
  setInputIcon(isShiftedHidden ? <FaEye /> : <FaEyeSlash />);
  setIsShiftedHidden(isShiftedHidden ? false : true);
}

const LoadingAnimation = () => (
  <div className="loading">
        <div className="loading-overlay">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>

  </div>
);


function logout() {
  localStorage.clear();
  sessionStorage.clear();
  navigate("/");
}

  // Rendering the tasks page
  return (
    <>
    { !token ? <Login /> : 
    < div className="flex-container" >
      <div className="tasks-container container">
        <YachtNavbar isAdmin= {isAdmin} getTasks={getTasks}/>
        <div className="box-user">
          <User
            key={user_id}
            forename={
              localStorage.getItem("forename")
              ? localStorage.getItem("forename")
              : sessionStorage.getItem("forename")
            }
            surname={
              localStorage.getItem("surname")
              ? localStorage.getItem("surname")
              : sessionStorage.getItem("surname")
            }
            team={
              <>
                {isBaseManager ? (
                  <span className="role">
                    {t("team_role_basemanager")}
                    {isAdmin && " (Admin)"}
                  </span>
                ) : (
                  <>
                    {getUserRoles}
                    {isAdmin && " (Admin)"}
                  </>
                )}
              </>
            }
          
      
            base={
              localStorage.getItem("selectedBaseText")
              ? localStorage.getItem("selectedBaseText")
              : localStorage.getItem("defaultBaseText")
            }
            date={selectedDate}
            />
        </div>
        <div>
          <div className="calendar">
            {/* <Button variant="secondary" size="lg" onClick={() => updateDate(new Date())}>Heute</Button> */}
            <div className="datePicker-wrapper">

              <div className="previousDay">
                <MdNavigateBefore fontSize="2em" onClick={() => {
                  console.log("In previous Date: " + startDate)
                  updateDate(getPreviousDay(startDate))
                  console.log("previous Date: " + startDate)
                }}
                />
              </div>
              {/* <Calendar onChange={onChange} value={selectedDate} local={appLanguage} /> */}
              <div className="datePicker home">
                <div className="today-home-btn">
                  <MdHome fontSize="2em" onClick={() => {
                    // console.log("In previous Date: " + startDate)
                    updateDate(new Date())
                    // console.log("previous Date: " + startDate)
                  }}
                  />
                </div>

                <DatePicker showIcon calendarStartDay={1} locale={i18n.language} selected={startDate} onChange={(date) => {
                  updateDate(date)
                  // setStartDate(date)
                  console.log("Datepicker - selected Date: " + date)
                }
              }
              onMonthChange={handleMonthChange}
              todayButton={t("today")}
              filterDate={isEnabledDay}
              dateFormat={dateFormat} />
              </div>
              <div className="nextDay">
                <MdNavigateNext fontSize="2em" onClick={() => {
                  console.log("In next Date: " + startDate)
                  updateDate(getNextDay(startDate))
                  console.log("Next Date: " + startDate)
                }} />
              </div>
            </div>
          </div>
        </div>
        {isInvalidToken && (
          <div className="invalid-token card alert alert-danger mb-3">
            <div className="card-body">
            <h4 className="alert-heading">{t("invalid_token_title")}</h4>
            <hr />
            <p>{t("invalid_token_message")}</p>
            <button
              className="logout form-control btn btn-primary btn-disabled rounded submit px-3"
              onClick={logout}
            >
              {t("Login again")}
            </button>
            {/* Additional content or actions can be added here if necessary */}
          </div>
          </div>
        )}
        {isError && (
          <div className="invalid-token card alert alert-danger mb-3">
            <div className="card-body">
            <h4 className="alert-heading">{t("error_title")}</h4>
            <hr />
            {errorMessage && (
              <p>{t("error_status")}: {errorMessage}</p>
            )}
            <p>{t("error_message")}</p>
            {/* Additional content or actions can be added here if necessary */}
            <button
              className="logout form-control btn btn-primary btn-disabled rounded submit px-3"
              onClick={logout}
            >
              {t("Reload the App")}
            </button>
          </div>
          </div>
        )}
        {isLoading && localStorage.getItem("BaseChanged") === "true" ? (
          <LoadingAnimation />
        ) : (
          <>
            {noTasks && (
              <div className="no-tasks card alert alert-success mb-3">
                {/* <div class="card-header">{t("no_tasks_title")}</div> */}
                <div className="card-body">
                  <h4 className="alert-heading">{t("no_tasks_title")}</h4>
                  <hr />
                  {/* <p>{t("no_tasks_text")}</p> */}
                  {isToday && <p>{t("no_tasks_text")}</p>}
                  {/* {!isToday && <p>Am {selectedDateFormated.toLocaleDateString()} gibt es keine Aufgaben für Dich.</p>} */}
                  {!isToday && <p>{t("no_tasks_on", {date: selectedDateFormated.toLocaleDateString()})}</p>}
                </div>
              </div>
            )}
            {!noTasks && 
              <>
                {renderedUserShiftedTasks.length > 0 &&  
                  <div className="box-yachts shifted">
                    < div className="shifted-title">
                      <div className="text">{t("shifted-tasks-first")}</div>
                      <div className="hide-show-password-eye"
                          onClick={hideShowShifted}
                          >
                          {inputIcon}
                        </div>
                    </div>
                    {!isShiftedHidden && renderedUserShiftedTasks}
                  </div>
                }
                <div className="box-yachts non-shifted">
                  {renderedUserNonShiftedTasks}
                </div>
              </>
            }
            { !noTasks && renderedUserNonShiftedTasks.length == 0 && renderedUserShiftedTasks.length == 0 &&    
              <div className="no-tasks card alert alert-success mb-3">
                {/* <div class="card-header">{t("no_tasks_title")}</div> */}
                <div className="card-body">
                  <h4 className="alert-heading">{t("no_tasks_title")}</h4>
                  <hr />
                  {/* <p>{t("no_tasks_text")}</p> */}
                  {isToday && <p>{t("no_tasks_text")}</p>}
                  {/* {!isToday && <p>Am {selectedDateFormated.toLocaleDateString()} gibt es keine Aufgaben für Dich.</p>} */}
                  {!isToday && <p>{t("no_tasks_on", {date: selectedDateFormated.toLocaleDateString()})}</p>}
                </div>
              </div>
          }
        </>
        )}
      </div>
    </div >
  }
          </>
  );
}

export default Tasks;
