import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import Form from "react-bootstrap/Form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { AppContext } from "../contexts/AppContext";

import "../App.css";
import logo from "../assets/logo.png";
import yachaIcon from "../assets/yacha-icon.png";
import ecosailIcon from "../assets/ecosail-icon.png";

/*
Login Page
- Display the login page.
- If user has been logged in, redirect to tasks.
- On submit  users authorization is verified with an API call.
- On success token and user data are saved in localStorage.
*/
function Login() {
  // Access global states and variables from context
  const {
    yachaUrl,
    yachtInProgress,
    isATaskActive,
    appLanguage,
    updateIsTaskActive,
    updateYachtInProgress,
    updateAppLanguage,
  } = useContext(AppContext);

  const { t, i18n } = useTranslation();

  // User data
  let [email, setEmail] = useState();
  let [username, setUserName] = useState();
  let [password, setPassword] = useState();
  let [forename, setForname] = useState();

  let [checked, setChecked] = useState(true);
  let [selected, setSelected] = useState("");

  // For hiding/showing password
  let [inputType, setInputType] = useState("password");
  let [inputIcon, setInputIcon] = useState(<FaEye />);

  // For managing data from API call
  let [data, setData] = useState();
  let [token, setToken] = useState();
  let [error, setError] = useState();
  let [invalidCridentials, setInvalidCridentials] = useState(false);

  // If already logged in (token exists), redirect to tasks page
  let navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
      navigate("/tasks");
    }
  }, []);

  // Toggle passwords inputfield to show or hide it
  function hideShowPassword(e) {
    setInputIcon(inputType === "text" ? <FaEye /> : <FaEyeSlash />);
    setInputType(inputType === "password" ? "text" : "password");
    console.log(inputType);
    console.log(inputIcon);
  }

  // Handle change when checkbox is checked/unchecked
  function handleChange(e) {
    setChecked(!checked);
  }

  const changeLanguage = (lng) => {
    setSelected(lng);
    lng = lng === "US" ? "en" : lng;
    localStorage.setItem("language", lng);
    updateAppLanguage(lng.toLowerCase());
    checked ? window.localStorage.setItem("language", lng):  sessionStorage.setItem("language", lng);
    i18n.changeLanguage(lng.toLowerCase());
    console.log("changelanguage: " + appLanguage + " " + localStorage.getItem("language"));
  };

  async function generateSHA512Hash(text) {
    try {
      const encoder = new TextEncoder();
      const data = encoder.encode(text);
      const hashBuffer = await crypto.subtle.digest('SHA-512', data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
      return hashHex;
    } catch (error) {
      console.error('Error generating SHA-512 hash:', error);
      return null;
    }
  }

  const generateDeviceId = async () => {
    const navigatorInfo = window.navigator;
    const screenInfo = window.screen;
    let uid = navigatorInfo.userAgent.replace(/\D+/g, '');
  
    console.log("UserAgent (numeric parts): " + uid);

    uid += screenInfo.height || '';
    console.log("Screen Height: " + screenInfo.height);
  
    uid += screenInfo.width || '';
    console.log("Screen Width: " + screenInfo.width);
  
    uid += screenInfo.pixelDepth || '';
    console.log("Screen Pixel Depth: " + screenInfo.pixelDepth);

    // Add browser language
    uid += navigator.language;
    console.log("Browser Language: " + navigator.language);

    // Add a feature detection fot serviceWorker
    uid += 'serviceWorker' in navigator ? '1' : '0';
    console.log("Service Worker Support: " + ('serviceWorker' in navigator ? '1' : '0'));

    console.log("UID before hashing: " + uid);

    // Convert the uid to a hash
    const hash = await generateSHA512Hash(uid);
    console.log("Hashed UID: " + hash);
  
    return hash; 
  };
  
  // Get user data by submitting username and password
  // On Success user data are received
  async function fetchData(e) {
    e.preventDefault();
  
    // Generate the SHA-512 hash
    const hash = await generateSHA512Hash(password);

    // Generate the device ID
    const deviceId = await generateDeviceId();
    console.log("device_id: " + deviceId);
  
    if (hash !== null && deviceId !== null ) {
      console.log('SHA-512 Hash:', hash);
  
      const url = `${yachaUrl}/yacha/checklist/user/auth/?username=${username}&password=${hash}&device_id=${deviceId}`;
      console.log("URL: " + url);
  
      // Continue with the rest of your code...
      await fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Sorry something went wrong");
          }
        })
        .then((data) => {
          setData(data);
          // Store user data in localStorage
          if (checked && data.token) {
            localStorage.setItem("token", data.token);
            localStorage.setItem("surname", data.surname);
            localStorage.setItem("forename", data.forename);
            localStorage.setItem("role_text", data.role_text);
            localStorage.setItem("base_text", JSON.stringify(data.base_text)); // Store base_text as a JSON string
            localStorage.setItem("user_id", data.id);
            localStorage.setItem("language", data.language);
            updateAppLanguage(data.language);
            localStorage.setItem("role", JSON.stringify(data.role));
            localStorage.setItem("base", JSON.stringify(data.base)); // Store base as a JSON string
            localStorage.setItem("defaultBase", (data.base[0]));
            localStorage.setItem("defaultBaseText", (data.base_text[0]));
            localStorage.setItem("tasks", JSON.stringify(data.tasks));
          } else if (data.token) {
            // or in sessionStorage if the user doesn't want to stay logged in
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("surname", data.surname);
            sessionStorage.setItem("forename", data.forename);
            sessionStorage.setItem("role_text", data.role_text);
            sessionStorage.setItem("base_text", JSON.stringify(data.base_text)); // Store base_text as a JSON string
            sessionStorage.setItem("user_id", data.id);
            sessionStorage.setItem("language", data.language);
            updateAppLanguage(data.language);
            sessionStorage.setItem("role", JSON.stringify(data.role));
            sessionStorage.setItem("base", JSON.stringify(data.base)); // Store base as a JSON string
            sessionStorage.setItem("tasks", JSON.stringify(data.base));
          }
          // On success navigate to the tasks page
          if (data.token != undefined) {
            setToken(data.token);
            navigate("/tasks");
            // Otherwise, the credentials are wrong, and an alert is displayed
          } else {
            setInvalidCridentials(true);
          }
        })
        .catch((error) => {
          setError(error.message);
        });
    } else {
      console.log('Hash generation failed.');
    }
  }
  
  // Render login page
  return (
    <div className="flex-container">
      <div className="login-container">
        <header className="">
          <img src={logo} className="yacha-logo" alt="logo" />
        </header>
        <div className="form-content align-self-center p-3">
          <div className="login-header mb-4">
            <h2 className="font-weight-bold">{t("login_title")}</h2>
            <div className="icons">
              <img src={yachaIcon} className="yacha-icon" alt="yacha" />
              <img src={ecosailIcon} className="ecosail-icon" alt="ecosail" />
            </div>
          </div>
          <Form className="form-group mt-3 mb-3" onSubmit={fetchData}>
            <div className="input-group mb-3">
              <input
                type="email"
                name="email"
                id="email"
                className="form-control"
                data-toggle="email"
                placeholder={t("email")}
                onChange={(event) => setUserName(event.target.value)}
                value={email}
              />
            </div>
            <div className="input-group mb-3">
              <input
                type={inputType}
                name="password"
                id="password"
                className="form-control"
                data-toggle="password"
                placeholder={t("password")}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <span
                onClick={hideShowPassword}
                className="input-group-text hide-show-password-eye"
              >
                {inputIcon}
              </span>
            </div>
            {invalidCridentials && (
              <div className="alert alert-danger" role="alert">
                {t("warning_invalid_credentials_text")}
              </div>
            )}
            <div className="form-group mb-4">
              <button
                disabled={!password || !username}
                type="submit"
                className="form-control btn btn-primary btn-disabled rounded submit px-3"
              >
                {t("login")}
              </button>
            </div>

            <div className="form-check mb-4">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckChecked"
                checked={checked}
                onChange={handleChange}
              />
              <label
                className="form-check-label text-primary"
                htmlFor="flexCheckChecked"
              >
                {t("stay_logged_in")}
              </label>
            </div>
          </Form>
          <ReactFlagsSelect
            defaultCountry="DE"
            countries={["DE", "US", "ES", "HR", "PL", "RU", "UA"]}
            selected={selected}
            onSelect={(code) => {
              changeLanguage(code);
            }}
            customLabels={{ US: "English", DE: "Deutsch" }}
            placeholder={t("change_language")}
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
