import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Offcanvas, Nav, Container, NavDropdown } from "react-bootstrap";
import { TbHelp } from "react-icons/tb";
import ReactFlagsSelect from "react-flags-select";

import { useTranslation } from "react-i18next";

import yachaIcon from "../assets/yacha-icon.png";
import ecosailIcon from "../assets/ecosail-icon.png";
import "../App.css";
import { getAppInfo } from "./Version.js";

function YachtNavbar(props) {
  const { t, i18n } = useTranslation();
  const { version, versionDate, companyName, year } = getAppInfo();

  const [selected, setSelected] = useState("");
  const [bases, setBases] = useState([]);
  const [basesTexts, setBasesTexts] = useState([]);
  const [selectedBase, setSelectedBase] = useState("");
  const [selectedBaseText, setSelectedBaseText] = useState(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedBases = JSON.parse(
      localStorage.getItem("base") || sessionStorage.getItem("base") || "[]"
    );
    console.log("Loaded bases:", storedBases);
    setBases(storedBases);

    const basesTexts = JSON.parse(
      localStorage.getItem("base_text") || sessionStorage.getItem("base_text") || "[]"
    );
    console.log("Loaded bases texts:", basesTexts);
    setBasesTexts(basesTexts);

    const storedSelectedBase =
      localStorage.getItem("selectedBase") ||
      sessionStorage.getItem("selectedBase") ||
      "";
    setSelectedBase(storedSelectedBase);
  }, []);

  function logout() {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  }

  const changeLanguage = (lng) => {
    setSelected(lng);
    lng = lng === "US" ? "en" : lng;
    i18n.changeLanguage(lng.toLowerCase());
    localStorage.setItem("language", lng.toLowerCase());
    sessionStorage.setItem("language", lng.toLowerCase());
    setShowOffcanvas(false); // Close the Offcanvas when language is selected
  };

  const handleBaseChange = (base) => {
    console.log("Selected base:", base);
  
    // Get the stored bases and base texts from localStorage or sessionStorage
    const storedBases = JSON.parse(
      localStorage.getItem("base") || sessionStorage.getItem("base") || "[]"
    );
    const baseTexts = JSON.parse(
      localStorage.getItem("base_text") || sessionStorage.getItem("base_text") || "[]"
    );
  
    // Find the index of the selected base
    const index = basesTexts.indexOf(base);
  
    // If the base is found, get the corresponding base text
    if (index !== -1) {
      const selectedBaseText = baseTexts[index];
      const selectedBase = storedBases[index];
      setSelectedBase(selectedBaseText);
      setSelectedBaseText(selectedBaseText);
      localStorage.setItem("selectedBaseText", selectedBaseText);
      sessionStorage.setItem("selectedBaseText", selectedBaseText);
      localStorage.setItem("selectedBase", selectedBase);
      sessionStorage.setItem("selectedBase", selectedBase);
      localStorage.setItem("BaseChanged", "true")
      props.getTasks();
      setShowOffcanvas(false); // Close the Offcanvas when base is selected
    } else {
      console.error(`Base '${base}' not found in storedBases.`);
    }
  };

  return (
    <Navbar fixed="top" className="navbar-header" expand={false}>
      <Container fluid>
        <div className="icons">
          <img src={yachaIcon} className="yacha-icon" alt="yacha" />
          <img src={ecosailIcon} className="ecosail-icon" alt="ecosail" />
        </div>
        <Navbar.Brand href="#">
          <span className="font-weight-bold">Yacha </span>
          {t("yacha_title")}
        </Navbar.Brand>

        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-false`} onClick={() => setShowOffcanvas(true)} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-false`}
          aria-labelledby={`offcanvasNavbarLabel-expand-false`}
          placement="end"
          show={showOffcanvas}
          onHide={() => setShowOffcanvas(false)}
        >
          <Offcanvas.Header closeButton>
            <div className="icons">
              <img src={yachaIcon} className="yacha-icon" alt="yacha" />
              <img src={ecosailIcon} className="ecosail-icon" alt="ecosail" />
            </div>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`}>
              <span className="font-weight-bold">Yacha</span>{" "}
              {t("yacha_title")}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="help">
              <Nav.Link
                href="https://www.charterzentrum.de/steg-app/how-to.html"
                target="_blank"
                className="item link video"
              >
                <TbHelp /> {t("help_video")}
              </Nav.Link>
            </Nav>
            {props.isAdmin && 
              <Nav className="justify-content-end pe-3 mb-3">
                <NavDropdown
                  title={selectedBaseText || t("Change base")}
                  className="nav-dropdown-base"
                  onSelect={handleBaseChange}
                >
                  {basesTexts.map((base, index) => (
                    <NavDropdown.Item
                      key={index}
                      eventKey={base}
                      active={base === selectedBase}
                    >
                      {base}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </Nav>
            }
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <ReactFlagsSelect
                defaultCountry="DE"
                countries={["DE", "US", "ES", "HR", "PL", "RU", "UA"]}
                selected={selected}
                onSelect={(code) => changeLanguage(code)}
                customLabels={{ US: "English", DE: "Deutsch" }}
                placeholder={t("change_language")}
              />
            </Nav>
            <button
              className="logout form-control btn btn-primary btn-disabled rounded submit px-3"
              onClick={logout}
            >
              {t("logout")}
            </button>
            <div className="navbar-bottom">
              <div className="copyright">
                © {year} – {companyName}
              </div>
              <div className="app-version">
                Version: {version} ({versionDate})
              </div>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default YachtNavbar;
